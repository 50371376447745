.questionary {
  label {
    margin-bottom: 0px !important;
  }
  .input-container {
    display: block;
  }
  .input-container > * {
    width: 100%;
  }
  .radio-options {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .radio-options .radio {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .fam-groups {
    display: flex;
    flex-direction: column;
  }

  input {
    margin: 0px !important;
    margin-bottom: 0px !important;
  }

  label.radio > input[type="radio"],
  span {
    margin-right: 5px !important;
  }

  .weekly-activity-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .weekly-activity-container .day-activity {
    display: flex;
    flex-direction: column;
  }

  .weekly-activity-container .day-activity * {
    color: #050505;
  }

  .food-frequency {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .food-frequency > * {
    width: 50%;
  }

  .data-form-titles-checks {
    flex-shrink: 0;
  }

  .step-controller {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .step-controller button {
    margin: 0px !important;
  }

  .day-activity.meal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 10px;
  }

  .meal-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .meal-description label {
    width: 100%;
    margin-bottom: 8px;
  }

  .meal-description textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
}

@media (min-width: 768px) {
  .questionary {
    .input-container {
      display: flex;
      gap: 8px;
    }

    .input-container > * {
      width: 50%;
    }
  }
}
